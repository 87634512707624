/**
 * @file This is the real JS entry point for an application.
 *
 * It is called through a <script> tag in the index.html entry point of the app.
 *
 * @module core/app.init.js
 * @packageDocumentation
 */

/*



--------------------------------------------------------------------------------
    IMPORT MODULES
--------------------------------------------------------------------------------



*/

import '@pkg/vendor/url-polyfill-1.1.0.min.js';
import '@pkg/vendor/file-polyfill.min.js';
import '@pkg/vendor/requestIdleCallback-shim.js';
import '@pkg/vendor/polyfill-classlist.js';
import '@pkg/vendor/polyfill-prepend.js';
import '@pkg/core/config.polyfills.js';
import configureApp from '@pkg/core/configure.app.js';
import config from '../../config/index.js';

/*

--------------------------------------------------------------------------------
    PRIVATE FUNCTIONS
--------------------------------------------------------------------------------

*/

const importPrivateBootstrap = () => {
  return import('./bootstrap.app.js').then(({ default: module }) => module);
};

const importStoreBundles = () => {
  return import('../store/bundles/index.js').then(
    ({ default: module }) => module
  );
};

const importClientDataBoot = () => {
  return import('./boot.clientData.js').then(({ default: module }) => module);
};

const importAppLoader = () => {
  return import('@pkg/core/boot/app.loader.js').then(
    ({ default: module }) => module
  );
};

const importBootSequence = ({ preconnect }) => {
  const bootImport = importPrivateBootstrap;
  // Preconnect to known domains in order to save time in DNS lookups,
  // TLS negotiations and TCP handshakes
  preconnect.execute();
  return Promise.all([bootImport(), importClientDataBoot()]).then(
    ([{ login, bootApp, closeApp }, { getClientData }]) => {
      login ? bootApp({ getClientData }) : closeApp();
    }
  );
};

const readLoginCookie = async () => {
  const loginCookie = await import('@pkg/core/utils/cookies/loginCookie.js');
  await loginCookie.readLoginCookie();
};

/*

--------------------------------------------------------------------------------
    ENTRY POINT
--------------------------------------------------------------------------------

*/

(async function() {
  await configureApp(config);
  await readLoginCookie();

  const { loadApp } = await importAppLoader();

  loadApp({
    bootSequence: importBootSequence,
    storeBundles: importStoreBundles
  });
})();
